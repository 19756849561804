import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './components/menu/menu.component';
import { TitlePageComponent } from './components/title-page/title-page.component';
// import { BlockUIModule } from 'ng-block-ui';
import { RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
// import { NgbModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
// import { SelectModule } from "../select/select.module";
import { BaCardComponent } from './components/ba-card/ba-card.component';
import { DepositPipePipe } from './pipes/deposit-pipe.pipe';
import { NoSpaceDirective } from './directives/no-space.directive';
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decimal-number-only.directive';
import { AccordianComponent } from './components/accordian/accordian.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { TrimOnBlurDirective } from './directives/trim-on-blur.directive';
import { CharacterOnlyDirective } from './directives/character-only.directive';
import { SelectModule } from '../select/select.module';
import { NbButtonModule, NbCardModule, NbCheckboxModule, NbDatepickerModule, NbInputModule, NbLayoutModule, NbTimepickerModule } from '@sb-accelerator/web-ui-sdk';
import { TableModule } from '../table/table.module';
import { AppRoutingModule } from '../../app-routing.module';
import { AppTranslationModule } from '../../app-translation.module';
@NgModule({
  declarations: [
    MenuComponent,
    TitlePageComponent,
    UnauthorizedComponent,
    BaCardComponent,
    DepositPipePipe,
    NumberOnlyDirective,
    TrimOnBlurDirective,
    TwoDigitDecimaNumberDirective,
    NoSpaceDirective,
    AccordianComponent,
    CharacterOnlyDirective,
  ],
  imports: [
    CommonModule,
    // BlockUIModule.forRoot(),
    RouterModule,
    NbButtonModule,
    // NgbModule
    SelectModule,
    NbDatepickerModule,
    NbInputModule,
    TableModule, NbCardModule,
    NbButtonModule,
    NbLayoutModule,
    NbInputModule,
    NbCheckboxModule,
    NbTimepickerModule,
    AppTranslationModule,
    
  ],
  exports:
    [
      MenuComponent,
      TitlePageComponent,
      // NgbAlertModule,
      UnauthorizedComponent,
      BaCardComponent,
      DepositPipePipe,
      NumberOnlyDirective,
      TrimOnBlurDirective,
      NoSpaceDirective,
      AccordianComponent,
      TwoDigitDecimaNumberDirective,
      SelectModule,SelectModule,
      NbButtonModule,
      TableModule,
      NbButtonModule,
      NbDatepickerModule,
      NbInputModule,
  
      NbCheckboxModule,
      NbTimepickerModule,
    ]
})
export class SharedModule { }

