<div class="containerWrap">
  <button nbButton type="button" class="accordion" #accordianTab>
    <em *ngIf="icon" [class]="classes" [ngStyle]="{'color': icon}" style="font-size: 20px;"></em>
    <strong style="font-size: 14px;">{{title}}</strong>
  </button>
  <div class=" panel animated  card ">
    <div class="card-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>