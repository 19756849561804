import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MenuComponent } from './pages/shared/components/menu/menu.component';
// import { LoginComponent } from './pages/shared/components/login/login.component';
// import { ForgotPasswordComponent } from './pages/shared/components/forgot-password/forgot-password.component';
// import { PasswordComponent } from './pages/shared/components/password/password.component';

export const routes: Routes = [
  { path: 'CASHIER/:userType/:userId/:sessId/:lang/:orgId/:merchantId/:domainId', component: MenuComponent },

  {
    path: 'CASHIER',
    loadChildren: () => import('./pages/transactions.module')
      .then(m => m.TransactionsModule),
  },

  { path: '', redirectTo: 'CASHIER', pathMatch: 'full' },
  { path: '**', redirectTo: 'CASHIER' }

];

const config: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
