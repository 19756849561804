declare var $: any;
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { map, shareReplay } from "rxjs/operators";
import { Router } from "@angular/router";
import { NbDateService, NbToastrService } from "@sb-accelerator/web-ui-sdk";
import { FormGroup, FormControl } from "@angular/forms";
const EXTENSIONS_MIMETYPE_MAP = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  bmp: "image/bmp",
  tiff: "image/tiff",
  webp: "image/webp",
  svg: "image/svg+xml",
  ico: "image/x-icon",
  css: "text/css",
  html: "text/html",
  js: "application/javascript",
  json: "application/json",
  xml: "application/xml",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  zip: "application/zip",
  tar: "application/x-tar",
  gz: "application/gzip",
  rar: "application/vnd.rar",
  mp3: "audio/mpeg",
  ogg: "audio/ogg",
  wav: "audio/wav",
  mp4: "video/mp4",
  avi: "video/x-msvideo",
  mkv: "video/x-matroska",
  mov: "video/quicktime",
  webm: "video/webm",
};

@Injectable({
  providedIn: "root",
})
export class CommonHelperService {
  private cache$;

  domain = environment.URL_NODE;
  responseMessages = {};
  customMessages = {};
  // @BlockUI() blockUI: NgBlockUI;
  menuList: any;
  constructor(
    private dateService: NbDateService<Date>,
    private http: HttpClient,
    private router: Router,
    private toasterService: NbToastrService
  ) {}

  makeRequest(
    data: any,
    url: string,
    loader: any,
    forceDisable: boolean = false,
    skipInterceptor: boolean = false
  ): Observable<any> {
    const options = this.generateRequestHeaders(
      loader,
      forceDisable,
      skipInterceptor
    );
    return this.http
      .post(`${this.domain}/api/PAYMENTS/${url}`, data, options)
      .pipe(map((response) => response));
  }

  generateEmbedFrameFrmBlob(
    blob: Blob,
    options: { height: string; width: string } = {
      height: "100%",
      width: "100%",
    }
  ) {
    const dataUrl: string = URL.createObjectURL(blob);
    return `<embed src="${dataUrl}" width="${options.width}" height="${options.height}">`;
  }

  openNewWindow(
    options: { width: number; height: number; left: number; top: number } = {
      width: 900,
      height: 600,
      left: 200,
      top: 100,
    }
  ): Window {
    return window.open(
      "",
      "",
      `width=${options.width}, height=${options.height}, left=${options.left}, top=${options.top}`
    );
  }

  getMimetypeOfFileExtension(
    fileExtension: keyof typeof EXTENSIONS_MIMETYPE_MAP
  ): string {
    return EXTENSIONS_MIMETYPE_MAP[`${fileExtension || ""}`.toLowerCase()];
  }

  fetchDocForPaymentCorrection(
    reqId,
    requestType,
    docPath,
    docStatus,
    directPrint: boolean = true
  ) {
    this.makeRequest(
      {
        token: localStorage.getItem("authToken"),
        reqId,
        docPath,
        docStatus,
        requestType,
      },
      `fetchDocForPaymentCorrection`,
      false,
      false,
      true
    ).subscribe((res) => {
      if (res.body.errorCode) {
        this.toasterService.success("Success", res.body?.message, {
          limit: 1,
          preventDuplicates: true,
        });
        return;
      }
      const blob = new Blob([res.body], {
        type: res.headers.get("content-type"),
      });
      const objectUrl = URL.createObjectURL(blob);
      setTimeout(() => {
        window.open(objectUrl, "_blank");
      }, 500);
    });
  }



  fetchWithdrawalDocByReqId(
    reqId,
    docPath,
    docStatus,
    directPrint: boolean = true
  ) {
    this.makeRequest(
      {
        token: localStorage.getItem("authToken"),
        reqId,
        docPath,
        docStatus,
      },
      `fetchWithdrawalDocByReqId`,
      false,
      false,
      true
    ).subscribe((res) => {
      if (res.body.errorCode) {
        this.toasterService.success("Success", res.body?.message, {
          limit: 1,
          preventDuplicates: true,
        });
        return;
      }
      const blob = new Blob([res.body], {
        type: res.headers.get("content-type"),
      });
      const objectUrl = URL.createObjectURL(blob);
      setTimeout(() => {
        window.open(objectUrl, "_blank");
      }, 500);
    });
  }

  makeRequestGet(
    data: any,
    url: string,
    loader: boolean,
    forceDisable: boolean = false,
    skipInterceptor: boolean = false
  ): Observable<any> {
    const options = this.generateRequestHeaders(
      loader,
      forceDisable,
      skipInterceptor
    );
    return this.http
      .get(`${this.domain}/api/PAYMENTS/${url}`, options)
      .pipe(map((response) => response));
  }

  generateRequestHeaders(
    loader: any,
    forceDisable: boolean,
    skipInterceptor: boolean
  ): any {
    const httpOptions = {
      headers: new HttpHeaders({
        showToast: `${loader}`,
        skipInterceptor: `${skipInterceptor}`,
        forceDisable: `${forceDisable}`,
      }),
      responseType: "json",
      observe: undefined,
    };
    const responseType = ('arraybuffer' || 'blob' || 'json' || 'text');

    if (skipInterceptor) {
      httpOptions.observe = "response";
      httpOptions.headers.append("skipInterceptor", `${skipInterceptor}`);
      httpOptions.responseType = responseType;
    }
    return httpOptions;
  }

  getCustomMessages(key) {
    return this.customMessages[key];
  }

  attachRespMessage(response): any {
    if (this.responseMessages[response.statusCode])
      response.message = this.responseMessages[response.statusCode];
    return response;
  }

  formatDate(date) {
    return this.dateService.format(date, "yyyy-MM-dd");
  }
  validateAllFormFields(formGroup: FormGroup) {
    this.validateFields(formGroup);
    if (!formGroup.valid) {
      let topObj;
      let el = $(".ng-invalid:not(form):first");
      if (el && el.offset()) {
        topObj = { scrollTop: el.offset().top - 150 };
      } else {
        topObj = { scrollTop: 0 };
      }
      $("html,body").animate({ scrollTop: topObj }, "slow", () => {
        el.focus();
      });
    }
  }
  validateFields(formGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  validateAllModalFormFields(formGroup: FormGroup) {
    this.validateFields(formGroup);
    if (!formGroup.valid) {
      let el = $(".ng-invalid:not(form):first");
      $("ngb-modal-window").animate(
        { scrollTop: el.offset().top - 150 },
        "slow",
        () => {
          el.focus();
        }
      );
    }
  }

  validateAllFormFieldsIncludingFomrArray(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      if (control.controls) {
        // control is a FormGroup
        this.validateAllFormFieldsIncludingFomrArray(control);
      } else {
        // control is a FormControl
        control.markAsTouched();
      }
    });
  }

  returnPagePermission(pageName): string[] {
    return JSON.parse(localStorage.getItem("permissions"))[pageName];
  }

  interval;
  animateMessage(that, className) {}

  animateModalMessage(that, className) {
    let el = $("." + className);
    $("ngb-modal-window").animate({ scrollTop: 50 }, "slow", () => {
      el.focus();
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        that.responseMessage = "";
        that.errorMessage = "";
        that.disableButton = false;
        clearInterval(this.interval);
      }, 3000);
    });
  }

  clearMessages(that) {
    that.responseMessage = "";
    that.errorMessage = "";
  }

  generateReceipts(data, url, loader): Observable<any> {
    if (loader) {
      // this.blockUI.start('Loading...');
    }
    return this.http.post(this.domain + "/api/PAYMENTS/" + url, data, {
      responseType: "blob",
      headers: new HttpHeaders().append(
        "Content-Type",
        "application/json; charset=utf-8"
      ),
    });
  }

  private getErrorMessage = new BehaviorSubject("");
  currentMessage = this.getErrorMessage.asObservable();

  changeMessage(url: string) {
    this.getErrorMessage.next(url);
  }

  private pageMenuTitle = new BehaviorSubject("");
  pageCurrentTitle = this.pageMenuTitle.asObservable();
  private menuArray = new BehaviorSubject([]);
  menuTitleArray = this.menuArray.asObservable();

  showName = new BehaviorSubject("");

  setPageTitle(title) {
    this.pageMenuTitle.next(title);
  }
  setMenuArray(array) {
    this.menuArray.next(array);
  }
  private menuData = new BehaviorSubject([]);
  menuDataList = this.menuData.asObservable();

  setMenuData(data) {
    this.menuData.next(data);
  }

  checkBusinessList(data) {
    if (true) {
      this.cache$ = this.getBusinessList(data).pipe(shareReplay(1));
    }
    return this.cache$;
  }

  getBusinessList(data) {
    let headers;
    headers = new HttpHeaders();
    return this.http.post(this.domain + "/api/PAYMENTS/getBusinessList", data, {
      headers,
    });
  }

  allowURL = ["/CASHIER/unauthorized", "/CASHIER/dashboard"];
  currentURl(url) {
    this.menuTitleArray.subscribe((res) => {
      if (res) var caption = res.filter((data) => data.relativePath == url);
      if (caption.length) {
        this.pageMenuTitle.next(caption[0].caption);
      } else {
        if (this.allowURL.filter((allowedUrl) => allowedUrl == url).length) {
          this.pageMenuTitle.next("");
        } else {
          this.router.navigate(["/CASHIER"]);
        }
      }
    });
  }

  urlExists(url) {
    return fetch(url, { mode: "no-cors" })
      .then((res) => {
        if (res.status == 0) return true;
        else return false;
      })
      .catch((err) => {
        return false;
      });
  }

  requestDateFormat(date): string {
    let stringDate: string = "";
    if (date) {
      let stringDay = isNumber(date.day) ? padNumber(date.day) : "";
      let stringMonth = isNumber(date.month) ? padNumber(date.month) : "";
      let stringYear = isNumber(date.year) ? (date.year - 0).toString() : "";
      stringDate = stringYear + "-" + stringMonth + "-" + stringDay;
    }
    return stringDate;
  }
  //get menu
  getMenu() {
    const data = {
      token: localStorage.getItem("authToken"),
      userId: localStorage.getItem("userId"),
      appType: "Web_Panel",
      engineCode: "CASHIER",
      languageCode: localStorage.getItem("lang"),
    };

    this.makeRequest(data, "getUserMenu", true).subscribe((res) => {
      if (res.errorCode == 0) {
        this.menuList = res.data.moduleBeanLst;
        let menuData = [];
        menuData.push({
          relativePath: "/CASHIER/change_password",
          caption: [this.getCustomMessages("changePwd")],
        });
        for (let menuList of this.menuList[0].menuBeanList) {
          if (menuList.relativePath) {
            menuData.push({
              relativePath: "/CASHIER/" + menuList.relativePath,
              caption: [menuList.caption],
            });
          }
          if (menuList.childMenuBean) {
            for (let childMenu of menuList.childMenuBean) {
              menuData.push({
                relativePath: "/CASHIER/" + childMenu.relativePath,
                caption: [menuList.caption, childMenu.caption],
              });
            }
          }
        }
        this.setMenuData(this.menuList);
        this.setMenuArray(menuData);
        this.currentURl(this.router.url);
        localStorage.setItem(
          "permissions",
          JSON.stringify(this.returnPermissions(this.menuList, {}))
        );
      } else if (res.errorCode == 9999) {
        this.changeMessage(res.message);
        this.router.navigate(["/CASHIER/unauthorized"]);
      } else {
        this.router.navigate(["/CASHIER/unauthorized"]);
      }
    });
  }

  returnPermissions(menu, permissionObj) {
    for (const obj of menu) {
      if (obj.menuBeanList) {
        for (const data of obj.menuBeanList) {
          if (data.permissionCodeList) {
            permissionObj["" + data.menuCode] = data.permissionCodeList;
          } else if (data.childMenuBean) {
            this.returnPermissions(data.childMenuBean, permissionObj);
          }
        }
      } else {
        this.getPermissions(menu, permissionObj);
      }
    }

    return permissionObj;
  }
  getPermissions(menu, permissionObj) {
    for (const x of menu) {
      if (x.permissionCodeList) {
        permissionObj["" + x.menuCode] = x.permissionCodeList;
      } else if (x.childMenuBean) {
        this.returnPermissions(x.childMenuBean, permissionObj);
      }
    }
  }
}

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}
