import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonHelperService } from '../../../../services/common-helper.service';
// import { CommonHelperService } from 'src/app/services/common-helper.service';


@Component({
  selector: 'app-title-page',
  templateUrl: './title-page.component.html',
  styleUrls: ['./title-page.component.scss']
})
export class TitlePageComponent implements OnInit , AfterViewInit{
  pageTitle;
  menu;
  menuData = [];
  name = '';
  selectedIndex = -1;
  @ViewChild('home') home: ElementRef;
  constructor(private router: Router,
    private commonHelperService: CommonHelperService) {
    this.commonHelperService.pageCurrentTitle.subscribe(res => {
      this.pageTitle = res;
    })
    this.commonHelperService.menuDataList.subscribe(res => {
       
      this.menu = res;
      if(this.menu.length<=0){
        this.commonHelperService.getMenu();
      }
    })
    this.commonHelperService.showName.subscribe(res => {
      this.name = res;
    })
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.commonHelperService.currentURl(event.url)
      }
    });
  }
  ngAfterViewInit(){
    this.home ? this.home.nativeElement.click() : '';
  }
  getMenuTitle(title) {
    this.pageTitle = title
  }

  setTitle(title) {
    this.commonHelperService.setPageTitle(title);
    return false;
  }
  setIndex(index) {
    this.selectedIndex = index;
  }
}
