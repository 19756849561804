import { Component, Input } from '@angular/core';

@Component({
  selector: 'ba-card',
  templateUrl: './ba-card.component.html',
  styleUrls: ['./ba-card.component.scss']
})
export class BaCardComponent {

  @Input() cardTitle: string;
  @Input() goBack: string;
  @Input() baCardClass: string;
  @Input() cardType: string;
  @Input() url: string;
  constructor() {
  }

  // ngOnChanges(changes) {
  //   if(changes?.cardTitle) {
  //     this.cardTitle = changes.cardTitle.currentValue;
  //   }
  //    
  // }
}
