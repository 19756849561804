import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'depositPipe'
})
export class DepositPipePipe implements PipeTransform {

  transform(array: any, query: any): any {

    if (query != undefined && query) {
     return array.filter(row => {
        const requestId = row.requestId.includes(query)
        const username = row.username.toLowerCase().includes(query.toLowerCase())
        return (requestId + username);
      })
    }
    return array;
  }

}
