import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
// import { CommonHelperService } from 'src/app/services/common-helper.service';
// import { environment } from 'src/environments/environment';
import { CommonHelperService } from '../../../../services/common-helper.service';
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  errorCode = localStorage.getItem('errorCode')
  message = '';

  constructor(private commonHelper: CommonHelperService) { }

  ngOnInit() {
    this.commonHelper.setMenuData([]);

    if (this.errorCode == '-1') {
      this.message = 'No Option Available For Your Account, Please Connect With Administrator.'
    } else {
      this.message = 'Session Expired. Login Again....'
      setTimeout(() =>
        parent.postMessage("logout", "*"),
        3000);
    }
  }

}
