import {
  Component,
  Input,
  OnInit,
  forwardRef,
  HostListener,
  HostBinding,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
@Component({
  selector: "sb-select",
  templateUrl: "./sbselect.component.html",
  styleUrls: ["./sbselect.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SbselectComponent,
      multi: true,
    },
  ],
})
export class SbselectComponent implements ControlValueAccessor {
  input = new FormControl("");

  constructor() { }
  subscriptions = [];
  registerOnChange(fn: any): void {
     
    this.subscriptions.push(
      this.input.valueChanges.subscribe(fn)
      // this.input.patchValue(this.data[0].value)
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }


  // Step 6: onTouch stays the same
  public onTouch: any = () => { };
  registerOnTouched(fn: any): void {
    this.onTouch = fn;

  }

  writeValue(input: string) {
    
    this.input.setValue(input);
  }
  _data: any;
  get data() {
    return this._data;
  }
  @Input() set data(value: any) {
    this._data = value;
  }

  @Input() placeholder;
  @Input() forcePatch = false;
  ngOnInit(): void { }
  ngDoCheck() { }
  ngOnChanges(changes) {
    if (
      changes.data?.currentValue?.length == 1 ||
      (changes.forcePatch?.currentValue && changes.data?.currentValue?.length)
    ) {
      setTimeout(()=>{

        this.input.patchValue(changes.data.currentValue[0].value);
      },0)
      return;
    }
  }
}
