import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SbselectComponent } from "./sbselect/sbselect.component";
import { NbCardModule, NbSelectModule } from "@sb-accelerator/web-ui-sdk";
import { AppTranslationModule } from "../../app-translation.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [SbselectComponent],
  imports: [
    CommonModule,
    NbSelectModule,
    AppTranslationModule,
    ReactiveFormsModule,
    NbCardModule
  ],
  exports: [SbselectComponent],
})
export class SelectModule {}
