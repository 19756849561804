<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <div class="contain logoWrap">
      <div>
        <div class="siteLogo">
          <a class="logo" href="#" (click)="navigateHome()"
            ><img alt="" src="../../../../assets/images/payprSiteLogo1.png"
          /></a>
        </div>
      </div>
    </div>
  </div>
  <nb-card>
    <nb-card-body class="example-items-col">
      <nb-toggle>LIGHT</nb-toggle>
    </nb-card-body>
  </nb-card>
</div>


<div class="marquee">
	<p>{{ marqueeMsg }}</p>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        [picture]="user?.picture"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
