import { Component, OnInit } from '@angular/core';
// import { CommonHelperService } from 'src/app/services/common-helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonHelperService } from '../../../../services/common-helper.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menuList = [];
  constructor(private commonHelper: CommonHelperService,
    private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
     

    localStorage.setItem("userId", this.route.snapshot.paramMap.get('userId'))
    localStorage.setItem("userType", this.route.snapshot.paramMap.get('userType'))
    localStorage.setItem("authToken", decodeURIComponent(this.route.snapshot.paramMap.get('sessId')))
    localStorage.setItem("lang", this.route.snapshot.paramMap.get('lang'))
    localStorage.setItem("orgId", this.route.snapshot.paramMap.get('orgId'))
    localStorage.setItem("merchantId", this.route.snapshot.paramMap.get('merchantId'))
    localStorage.setItem("channelId", this.route.snapshot.paramMap.get('channelId'))
    this.router.navigateByUrl('/pages/CASHIER/CASHIER');

    // this.commonHelper.getMenu();
    // const data = {
    //   token: localStorage.getItem('authToken'),
    //   userId: localStorage.getItem('userId'),
    //   appType: "Web_Panel",
    //   engineCode: "CASHIER",
    //   languageCode: localStorage.getItem('lang'),
    // };

    // this.commonHelper.makeRequest(data, 'getUserMenu', true).subscribe(res => {
    //   if (res.errorCode == 0) {
    //     this.menuList = res.data.moduleBeanLst;
    //     let menuData = [];
    //     menuData.push({ relativePath: '/CASHIER/change_password', caption: [this.commonHelper.getCustomMessages("changePwd")] });
    //     for (let menuList of this.menuList[0].menuBeanList) {
    //       if (menuList.relativePath) {
    //         menuData.push({ relativePath: '/CASHIER/' + menuList.relativePath, caption: [menuList.caption] });
    //       }
    //       if (menuList.childMenuBean) {
    //         for (let childMenu of menuList.childMenuBean) {
    //           menuData.push({ relativePath: '/CASHIER/' + childMenu.relativePath, caption: [menuList.caption, childMenu.caption] });
    //         }
    //       }
    //     }
    //     this.commonHelper.setMenuData(this.menuList);
    //     this.commonHelper.setMenuArray(menuData);
    //     this.commonHelper.currentURl(this.router.url)
    //     localStorage.setItem('permissions', JSON.stringify(this.returnPermissions(this.menuList, {})));
    //   } else if (res.errorCode == 9999) {
    //     this.commonHelper.changeMessage(res.message);
    //     this.router.navigate(['/CASHIER/unauthorized'])
    //   }
    //   else {
    //     this.router.navigate(['/CASHIER/unauthorized'])
    //   }
    // });
  }
  // returnPermissions(menu, permissionObj) {
  //   for (const obj of menu) {
  //     if (obj.menuBeanList) {
  //       for (const data of obj.menuBeanList) {
  //         if (data.permissionCodeList) {
  //           permissionObj['' + data.menuCode] = data.permissionCodeList;
  //         } else if (data.childMenuBean) {

  //           this.returnPermissions(data.childMenuBean, permissionObj);
  //         }
  //       }
  //     } else {
  //       this.getPermissions(menu, permissionObj);
  //     }
  //   }

  //   return permissionObj;
  // }
  // getPermissions(menu, permissionObj) {
  //   for (const x of menu) {
  //     if (x.permissionCodeList) {
  //       permissionObj['' + x.menuCode] = x.permissionCodeList;
  //     } else if (x.childMenuBean) {
  //       this.returnPermissions(x.childMenuBean, permissionObj);
  //     }
  //   }
  // }
}
