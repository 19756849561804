import { AfterViewInit, Component } from "@angular/core";
import {
  NbMenuInternalService,
  NbMenuItem,
  NbMenuService,
} from "@sb-accelerator/web-ui-sdk";
import { CommonHelperService } from "../../../services/common-helper.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from 'rxjs/operators';

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <div>
      <div class="subheader_wrapper">
        <div><ng-content select="nb-menu"></ng-content></div>
      </div>
      <div style="margin-top:-60px">
        <nb-layout>
          <nb-layout-column>
            <nb-card *ngIf="bread">
              <nb-card-body>
                <div>
                  <div class="breadCrumbWrap">
                    <div class="containerWrap">
                      <div class="breadCrumb">
                        <div class="bread_crum_wrapper">
                          {{ bread }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="route_outlet" id="scrollContainer">
                  <ng-content select="router-outlet"></ng-content>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-layout-column>
        </nb-layout>
      </div>
    </div>
  `,
})
export class OneColumnLayoutComponent implements AfterViewInit {
  pageTitle;
  tada = [];
  bread;
  menuS;
  constructor(
    private commonHelper: CommonHelperService,
    private sidebarService: NbMenuService,
    private service: NbMenuInternalService,
    private router: Router,
    
  ) {
    this.sidebarService.onItemClick().subscribe((res) => {
      this.bread = res.item.data.breadCrumb;
    });

    this.sidebarService.onItemClick().subscribe((res) => {
      this.bread = res.item?.data?.breadCrumb;
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateBreadcrumb();
    });

    this.sidebarService.getSelectedItem().subscribe((res) => {});
    this.commonHelper.pageCurrentTitle.subscribe((res) => {
      this.pageTitle = res;
    });

    this.commonHelper.menuDataList.subscribe((menu) => {
      this.menuS = menu;
    });
    this.sidebarService.onItemHover().subscribe((res) => {
      res.item.expanded = true;

      const menus = this.menuS.filter(
        (menu) => menu.data?.tag !== res.item?.data?.tag
      );
      if (res.item) this.service.collapseAll(menus, "");
    });
      // this.sidebarService.onHoverOutItem().subscribe((res) => {
    //   if(!res?.item?.children?.length){
    //     res.item.expanded = false;
    // }
    //     const menus = this.menuS.filter(
    //       (menu) => menu.data?.tag !== res.item?.data?.tag
    //     );
    //     if (res.item) this.service.collapseAll(menus, "");
    //   // }

    // });
  }

  ngAfterViewInit() {
    document
      .getElementById("cashier-menu")
      ?.addEventListener("mouseleave", () => {
        this.service.collapseAll(this.menuS, "");
      });
  }

  ngOnInit() {}
  getMenuTitle(title) {
    this.pageTitle = title;
  }

  private updateBreadcrumb(): void {
    if (!this.menuS?.length) return;
    const url = this.router.url;
    const resultObject = this.findObjectByLink(this.menuS, url);
    this.bread = resultObject?.data?.breadCrumb || "";
  }

  private findObjectByLink(
    dataArray: NbMenuItem[],
    targetLink: string
  ): NbMenuItem | null {
    for (const item of dataArray) {
      if (item?.link?.includes(targetLink)) {
        return item;
      } else if (item.children) {
        const childItem = item.children.find((child) =>
          child.link.includes(targetLink)
        );
        if (childItem) {
          return childItem;
        }
      }
    }
    return null;
  }

  hide() {
    this.sidebarService.onItemHover().subscribe((res) => {
      res.item.expanded = false;
    });
  }
  setTitle(title) {
    this.commonHelper.setPageTitle(title);
    return false;
  }
}
