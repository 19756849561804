<nb-card>
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container [matColumnDef]="column.key" *ngFor="let column of displayedColumns;index as i">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 8px !important;padding-right: 10px !important;">
        <span *ngIf="!(column.headerTemplateType)"> {{ column.value | translate }}</span>
        <ng-container *ngIf="column.headerTemplateType" [ngTemplateOutlet]="itemTemplate[column.headerTemplateType]">

        </ng-container>
      </th>
      <td mat-cell *matCellDef="let element ; index as index11 "(click)="logIndex(i) " width="{{column.width}}%" style="padding-left: 15px !important;padding-right: 10px !important;">

        <span *ngIf="!(column.templateType)"> {{ element[column.key] }} </span>
        <ng-container *ngIf="column.templateType" [ngTemplateOutlet]="itemTemplate[column.templateType]"
          [ngTemplateOutletContext]="{ $implicit: element,index11:(paginator.pageIndex == 0 ? index11  :  index11 + paginator.pageIndex * paginator.pageSize) }"></ng-container>
      </td>
    </ng-container>


    <tr nbTreeGridHeaderRow mat-header-row *matHeaderRowDef="columnNames"></tr>
    <tr nbTreeGridRow mat-row *matRowDef="let row; columns:columnNames;let j=index"(click)="logIndex(j) ">{{row}}</tr>

  </table>
  <mat-paginator #paginator class="pagination_wrapper" [pageSizeOptions]="[5, 10, 20]" [length]="dataSource?.data?.length" [pageSize]="5"
    showFirstLastButtons>
  </mat-paginator>
</nb-card>