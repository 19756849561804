import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent implements OnInit {
  @Input() title;
  @Input() icon;
  @Input() classes;
  @Input() active;
  @ViewChild('accordianTab') accordianTab: ElementRef<HTMLElement>;

  ngOnInit() {
    if (this.active) {
      let accordianTab: HTMLElement = this.accordianTab.nativeElement;
      accordianTab.click();
    }
  }

  @HostListener('click', ['$event']) onclick(event) {
    var panel = event.srcElement.nextElementSibling;
    if (panel) {
      event.srcElement.classList.toggle("active");
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = "100%";
      }
    }
  }
}


