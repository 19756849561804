<div class="pageNavWrap">
  <div class="containerWrap">
    <ul *ngIf="menu && menu[0]" class="pageNav top-level-menu">
      <ng-container *ngFor="let menuList of menu[0].menuBeanList;index as i">
        <li class="item " *ngIf="menuList.menuCode === 'CASHIER_HOME';else elseBlock"
          [ngClass]="{'active': selectedIndex == -1}" (click)="setIndex(-1)"><a #home routerLink="dashboard"
            class="link">{{menuList.caption}}</a></li>
        <ng-template #elseBlock>
          <li class="item" [ngClass]="{'active': selectedIndex == i}" (click)="setIndex(i)">
            <a class="link" *ngIf="menuList?.relativePath" routerLink={{menuList.relativePath}}>{{menuList.caption}}</a>
            <a class="link" *ngIf="! menuList?.relativePath">{{menuList.caption}}</a>
            <ul class="third-level-menu" *ngIf="menuList.childMenuBean">
              <li class="item" *ngFor="let childMenu  of menuList.childMenuBean">
                <a class="link" routerLink={{childMenu.relativePath}}>{{childMenu.caption}}</a>
              </li>
            </ul>
          </li>
        </ng-template>
      </ng-container>
    </ul>
  </div>
</div>

<div class="breadCrumbWrap" *ngIf="pageTitle && pageTitle[pageTitle.length-1] !='Home'">
  <div class="containerWrap">
    <div class="breadCrumb">
      <ng-container *ngFor="let title of pageTitle;index as i ;">
        <span class="link" *ngIf="i != pageTitle.length-1">{{title}}</span>
        <span class="divider" *ngIf="i != pageTitle.length-1"></span>
      </ng-container>
      <span class="link active">{{pageTitle[pageTitle.length-1]}}</span>
    </div>
  </div>
</div>
<div class="pageMainTitle" *ngIf="pageTitle && pageTitle[pageTitle.length-1] !='Home' && !name">
  <div class="containerWrap">
    <h1 class="al-title">{{pageTitle[pageTitle.length-1]}}</h1>
  </div>

</div>
<div class="pageMainTitle" *ngIf="name">
  <div class="containerWrap">
    <h1 class="al-title">{{name}}</h1>
  </div>
</div>